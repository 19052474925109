import { reactive }   from "vue";
import DevicesRefresh from "@/classes/devices/Refresh";

export default class Devices
{

    constructor( core )
    {

        if( !Devices.instance )
        {

            this.core = core

            this.config = core.getConfig()
            this.crypto = core.getCrypto()
            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.database = core.getDatabase()
            this.store = core.getStore()
            this.ui = core.getUi()
            this.keyRing = core.getKeyRing()
            this.client = core.getClient()
            this.f = core.f()
            this.router = core.getRouter()
            this.coreTimer = core.getCoreTimer()
            this.sorter = core.getSorter()

            this.cache = reactive( {
                devices     : {},
                categories  : {},
                cameras     : {},
                rooms       : {},
                scripts     : {},
                cacheKey    : false,
                unsorted    : [],
                unsortedHash: false
            } )

            this.cachePrepared = false

            this.logger.log( 'Devices manager warming up' )
            this.ready = false

            this.refresh = new DevicesRefresh( this )

            this.setup()

            Devices.instance = this

        }

        return Devices.instance

    }

    awaitReadiness( retry )
    {

        return new Promise( ( resolve, reject ) =>
        {

            retry = retry || 0

            if( 10 === retry )
            {
                this.setup()
                return
            }

            if( undefined === this.keyRing.ring.openSetup
                || undefined === this.keyRing.ring.openSetup.userid )
            {
                return reject( 'NOT PROVISIONED' )
            }

            if( this.ready )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    retry++
                    return resolve( this.awaitReadiness( retry ) )
                }, 300 )
            }

        } )
    }

    awaitCache()
    {
        return new Promise( resolve =>
        {

            if( this.cachePrepared )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitCache() )
                }, 300 )
            }

        } )
    }

    restoreObjects( objects )
    {
        return new Promise( resolve =>
        {

            if( undefined !== objects.devices
                && 0 < objects.devices.length )
            {
                this.database.writeList( 'devices', objects.devices )
                    .then( () =>
                    {
                        delete objects.devices
                        return resolve( this.restoreObjects( objects ) )
                    } )
            }
            else if( undefined !== objects.rooms
                     && 0 < objects.rooms.length )
            {
                this.database.writeList( 'rooms', objects.rooms )
                    .then( () =>
                    {
                        delete objects.rooms
                        return resolve( this.restoreObjects( objects ) )
                    } )
            }
            else if( undefined !== objects.categories
                     && 0 < objects.categories.length )
            {
                this.database.writeList( 'categories', objects.categories )
                    .then( () =>
                    {
                        delete objects.categories
                        return resolve( this.restoreObjects( objects ) )
                    } )
            }
            else if( undefined !== objects.scripts
                     && 0 < objects.scripts.length )
            {
                this.database.writeList( 'scripts', objects.scripts )
                    .then( () =>
                    {
                        delete objects.scripts
                        return resolve( this.restoreObjects( objects ) )
                    } )
            }
            else if( undefined !== objects.cameras
                     && 0 < objects.cameras.length )
            {
                this.database.writeList( 'cameras', objects.cameras )
                    .then( () =>
                    {
                        delete objects.cameras
                        return resolve( this.restoreObjects( objects ) )
                    } )
            }
            else
            {
                return resolve()
            }

        } )
    }

    restore( userid, result, gotSome )
    {
        return new Promise( resolve =>
        {
            if( 0 < result.length )
            {

                let filename = result.shift(),
                    message  = {
                        method  : 'endpoint.readBackupFile',
                        serial  : userid,
                        filename: filename,
                        scope   : 'client'
                    }

                this.client.request( message, 'remote' )
                    .then( response =>
                    {

                        this.ui.showBlocker( '<strong>' + result.length + '</strong> Backup-Partitionen verbleiben<br/>Augenblick bitte...' )
                        let setup = this.crypto.decrypt( response )
                        if( false !== setup )
                        {

                            let objects = JSON.parse( setup )
                            this.restoreObjects( objects )
                                .then( () =>
                                {
                                    return resolve( this.restore( userid, result, true ) )
                                } )

                        }
                        else
                        {
                            return resolve( this.restore( userid, result, true ) )
                        }

                    } )

            }
            else
            {
                return resolve( gotSome )
            }
        } )
    }

    tryRestore( userid )
    {

        return new Promise( resolve =>
        {

            let message = {
                method: 'endpoint.getAvailableBackupFiles',
                serial: userid,
                scope : 'client'
            }

            this.client.request( message, 'remote' )
                .then( result =>
                {

                    if( 0 < result.length )
                    {
                        this.ui.showBlocker( '<strong>' + result.length + '</strong> Backup-Partitionen gefunden<br/>Wiederherstellung wird gestartet...' )
                        result.sort( function( x, y )
                        {
                            return parseInt( x.split( '-' )[ 0 ] ) < parseInt( y.split( '-' )[ 0 ] ) ? -1 : 1
                        } )
                        return resolve( this.restore( userid, result ) )
                    }
                    else
                    {
                        this.ui.showBlocker( '<strong>Kein brauchbares Backup gefunden</strong>: breche ab.' )
                        return resolve()
                    }

                } )
                .catch( e =>
                {
                    console.error( e )
                    return resolve()
                } )

        } )

    }

    refreshSingleCache( scope )
    {

        console.log( 'DBG > REFRESH SINGLE', scope )

        let promises = [],
            sortId   = 0

            promises.push( () =>
            {

                return this.database.readAll( scope )
                           .then( list =>
                           {
                               for( let l in list )
                               {

                                   if( undefined === list[ l ].item.sortId )
                                   {
                                       list[ l ].item.sortId = sortId
                                       sortId++
                                   }

                                   this.f.deviceHash( list[ l ].item )
                                   this.cache[ scope ].map.set( list[ l ].key, list[ l ].item )
                                   this.eventManager.dispatch( 'on-cache-item-key-' + list[ l ].key, list[ l ].item.elementKey )
                                   this.cache[ scope ].hasher[ list[ l ].key ] = list[ l ].item.elementKey

                               }
                               this.f.deviceHash( this.cache[ scope ].hasher )
                               this.cache[ scope ].cacheKey = this.cache[ scope ].hasher.elementKey

                           } )

            } )

        this.f.promiseRunner( promises )
            .then( () =>
            {

                this.cache.cacheKey = this.f.deviceHash( this.cache, true )
                this.cachePrepared = true

            } )

    }

    prepareCache( refresh )
    {

        return new Promise( resolve =>
        {

            let keys     = [ 'devices', 'categories', 'rooms', 'scripts', 'cameras' ],
                promises = [],
                unsorted = [],
                allRooms = [],
                sortId   = 0

            for( let k in keys )
            {

                promises.push( () =>
                {

                    return this.database.readAll( keys[ k ] )
                               .then( list =>
                               {

                                   for( let l in list )
                                   {
                                       if( -1 === this.cache[ keys[ k ] ].keys.indexOf( list[ l ].key )
                                           || refresh )
                                       {
                                           if( !refresh )
                                           {
                                               this.cache[ keys[ k ] ].keys.push( list[ l ].key )
                                           }
                                           if( undefined === list[ l ].item.sortId )
                                           {
                                               list[ l ].item.sortId = sortId
                                               sortId++
                                           }

                                           this.f.deviceHash( list[ l ].item )
                                           this.cache[ keys[ k ] ].map.set( list[ l ].key, list[ l ].item )
                                           this.eventManager.dispatch( 'on-cache-item-key-' + list[ l ].key, list[ l ].item.elementKey )
                                           this.cache[ keys[ k ] ].hasher[ list[ l ].key ] = list[ l ].item.elementKey
                                       }
                                   }
                                   this.f.deviceHash( this.cache[ keys[ k ] ].hasher )
                                   this.cache[ keys[ k ] ].cacheKey = this.cache[ keys[ k ] ].hasher.elementKey

                               } )

                } )

            }

            if( undefined !== this.cache.rooms
                && Array.isArray( this.cache.rooms.keys ) )
            {
                for( let k in this.cache.rooms.keys )
                {

                    let key  = this.cache.rooms.keys[ k ],
                        room = this.cache.rooms.map.get( key )

                    for( let d in room.devices )
                    {

                        allRooms.push( room.devices[ d ] )

                    }

                }
            }

            this.cache.unsortedHash = this.f.deviceHash( unsorted, true )

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    if( 0 < allRooms.length )
                    {

                        if( undefined !== this.cache.devices
                            && Array.isArray( this.cache.devices.keys ) )
                        {

                            for( let k in this.cache.devices.keys )
                            {
                                let key = this.cache.devices.keys[ k ]
                                if( -1 === allRooms.indexOf( key )
                                    && -1 === this.cache.unsorted.indexOf( key ) )
                                {
                                    let device = this.cache.devices.map.get( key )
                                    device.displayName = device.name
                                    device.isUnsorted = true
                                    this.f.deviceHash( device )
                                    this.cache.devices.hasher[ key ] = device.elementKey
                                    this.cache.unsorted.push( key )
                                }
                            }

                        }

                        this.f.deviceHash( this.cache.devices.hasher )
                        this.cache.devices.cacheKey = this.cache.devices.hasher.elementKey

                    }

                    this.cache.cacheKey = this.f.deviceHash( this.cache, true )
                    this.cachePrepared = true
                    return resolve()

                } )


        } )

    }


    setup()
    {

        let keys = [ 'devices', 'categories', 'rooms', 'scripts', 'cameras' ]
        for( let k in keys )
        {
            this.cache[ keys[ k ] ] = reactive( {
                keys    : [],
                hasher  : {},
                map     : new Map(),
                cacheKey: false
            } )
        }

        this.database.readDevices()
            .then( devices =>
            {

                if( 0 === devices.length )
                {

                    if( undefined !== this.keyRing.ring.openSetup
                        && undefined !== this.keyRing.ring.openSetup.userid )
                    {
                        let blockerStays = false
                        this.ui.showBlocker( '<strong>Keine Geräte vorhanden</strong>: Es wird nach einem Backup gesucht...' )
                        this.client.awaitReadiness()
                            .then( () =>
                            {

                                this.keyRing.awaitReadiness()
                                    .then( () =>
                                    {
                                        this.tryRestore( this.keyRing.ring.openSetup.userid )
                                            .then( ( state ) =>
                                            {
                                                if( true === state )
                                                {
                                                    blockerStays = true
                                                    this.ui.showBlocker( '<strong>Backup wiederherstellt</strong>: Gleich geht\'s weiter...' )
                                                    setTimeout( () =>
                                                    {
                                                        window.location.reload()
                                                    }, 5000 )
                                                }
                                            } )
                                            .catch( () =>
                                            {
                                            } )
                                            .finally( () =>
                                            {
                                                this.prepareCache()
                                                if( !blockerStays )
                                                {
                                                    this.ui.hideBlocker()
                                                }
                                                this.ready = true
                                            } )
                                    } )

                            } )
                    }
                    else
                    {
                        this.router.push( '/' )
                    }
                }
                else
                {
                    this.logger.clog( 'Devices::setup', devices.length + ' devices parsend and prepared' )
                    this.prepareCache()
                        .then( () =>
                        {
                            this.ready = true
                        } )
                }

            } )

    }

    unifySortIds( devices )
    {

        let sortId = 0
        for( let d in devices )
        {
            devices[ d ].sortId = sortId
            sortId++
        }

    }

    writeElementList( elements )
    {

        let dbList = []
        for( let e in elements )
        {
            dbList.push( {
                key : elements[ e ].name,
                item: JSON.parse( JSON.stringify( elements[ e ] ) )
            } )
        }

        this.database.writeList( 'devices', dbList )
            .then( () =>
            {
                console.log( 'DBG > STORED' )
            } )
            .catch( e =>
            {
            } )
    }

    refineIds( room, event )
    {

        let todoList = room.devices,
            sortList = [],
            addOne   = false,
            newElms  = []

        event.oldIndex = event.oldIndex || 99999

        for( let t in todoList )
        {
            let device = this.cache.devices.map.get( todoList[ t ] )
            if( device )
            {
                sortList.push( device )
            }
        }

        sortList = this.sorter.sortObjects( sortList, 'sortId', 'ascending' )
        this.unifySortIds( sortList )

        let changeDevice = false
        for( let s in sortList )
        {
            let device = sortList[ s ]
            if( device && ( device.sortId === event.oldIndex ) )
            {
                device.sortId = event.newIndex
                changeDevice = JSON.parse( JSON.stringify( device ) )
                this.f.removeFromArray( sortList, changeDevice )
            }
            else
            {
                newElms.push( device )
            }
        }

        if( !changeDevice )
        {
            changeDevice = JSON.parse( JSON.stringify( event.element ) )
        }

        this.unifySortIds( newElms )

        for( let s in newElms )
        {
            let device = newElms[ s ]
            if( device && ( device.sortId === event.newIndex ) )
            {
                addOne = true
            }
            if( addOne )
            {
                device.sortId += 1
            }
        }

        newElms.push( changeDevice )
        newElms = this.sorter.sortObjects( sortList, 'sortId', 'ascending' )
        this.writeElementList( newElms )

    }

    moveDevice( room, event )
    {

        if( room.id === 'unsorted' )
        {
            return
        }

        let targetRoom = this.cache.rooms.map.get( room.id )

        if( targetRoom )
        {

            if( undefined !== event.moved )
            {
                this.refineIds( targetRoom, event.moved )
            }

            if( undefined !== event.added )
            {
                targetRoom.devices.push( event.added.element.name )
                this.database.readAll( 'rooms' )
                    .then( rooms =>
                    {

                        let databaseRoom = rooms.find( r => r.key === targetRoom.id ),
                            newRoom      = databaseRoom.item
                        if( -1 === newRoom.devices.indexOf( event.added.element.name ) )
                        {
                            newRoom.devices.push( event.added.element.name )
                            this.database.write( 'rooms', targetRoom.id, newRoom )
                        }

                        this.refineIds( targetRoom, event.added )

                    } )
            }
            if( undefined !== event.removed )
            {

                this.f.removeFromArray( targetRoom.devices, event.removed.element.name )

                this.database.readAll( 'rooms' )
                    .then( rooms =>
                    {

                        let databaseRoom = rooms.find( r => r.key === targetRoom.id ),
                            newRoom      = databaseRoom.item
                        if( -1 < newRoom.devices.indexOf( event.removed.element.name ) )
                        {
                            this.f.removeFromArray( newRoom.devices, event.removed.element.name )
                            this.database.write( 'rooms', targetRoom.id, newRoom )
                        }

                        this.refineIds( targetRoom, event.removed )

                    } )

            }
        }
    }

}