export default class DevicesRefresh
{

    constructor( parent )
    {

        if( !DevicesRefresh.instance )
        {

            this.parent = parent
            this.logger = parent.logger
            this.client = parent.client
            this.crypto = parent.crypto
            this.coreTimer = parent.coreTimer
            this.eventManager = parent.eventManager
            this.f = parent.f

            this.getState = ( key ) =>
            {
                return parent.core.getState( key )
            }

            this.interval = 5000
            this.intervalCameras = 20000
            this.firstrun = true

            this.logger.log( 'DevicesRefresh::startup', 'polling interval is set to ' + this.interval + 'ms' )

            this.paused = false
            this.ready = true

            this.eventManager.add( 'on-device-refresh-pause', ( state ) =>
            {
                this.paused = state
            } )

            this.coreTimer.addInterval( 'devices-refresh', this.interval, () =>
            {
                console.log( 'DBG > DEV-RFRSH', 'FETCH ALL' )
                this.fetchAll()
            } )

            this.coreTimer.addInterval( 'cameras-refresh', this.intervalCameras, () =>
            {
                this.fetchImages()
            } )

            setTimeout( () =>
            {
                this.fetchAll()
            }, 1000 )

            setTimeout( () =>
            {
                this.fetchImages()
            }, 5000 )

            setTimeout( () =>
            {
                this.fetchFullDevicesList()
            }, 20000 )

            DevicesRefresh.instance = this

        }

        return this

    }

    /*eslint-disable*/
    updateDeviceReadings( readingsList )
    {

        return new Promise( resolve =>
        {

            let dbList = []

            while( readingsList.length > 0 )
            {

                let readings = readingsList.shift()

                for( let d in this.parent.cache.devices.keys )
                {

                    let key = this.parent.cache.devices.keys[ d ]

                    if( key === readings.Name )
                    {

                        let device = this.parent.cache.devices.map.get( this.parent.cache.devices.keys[ d ] )

                        if( ( 'HTTPMOD' === device.type || 'GasStation' === device.type )
                            && undefined !== device.internals.mainurl
                            && -1 < device.internals.mainurl.indexOf( 'tankstelle' ) )
                        {

                            device.readings = readings.Readings
                            device.readings.deviceType = 'GasStation'
                            console.log( readings )
                        }

                        if( undefined === device.readings )
                        {
                            device.readings = {}
                        }
                        if( undefined === device.internals )
                        {
                            device.internals = {}
                        }
                        if( readings.Internals.STATE )
                        {
                            device.internals.state = readings.Internals.STATE
                        }

                        if( undefined !== readings.Readings.deviceType )
                        {
                            device.readings.deviceType = readings.Readings.deviceType
                        }
                        if( undefined !== readings.Attributes.userattr )
                        {
                            let userattr = readings.Attributes.userattr.split( / /g )
                            if( 'deviceType' === userattr[ 0 ] )
                            {
                                device.readings.deviceType = userattr[ 1 ]
                            }
                        }
                        if( undefined !== readings.Readings.energy )
                        {
                            device.readings.energy = readings.Readings.energy
                        }
                        if( undefined !== readings.Readings.power )
                        {
                            device.readings.power = readings.Readings.power
                        }
                        if( undefined !== readings.Readings[ 'tC' ] )
                        {
                            device.readings[ 'tC' ] = {}
                            device.readings[ 'tC' ].Value = readings.Readings[ 'tC' ].Value
                        }
                        if( undefined !== readings.Readings[ 'rh' ] )
                        {
                            device.readings[ 'rh' ] = {}
                            device.readings[ 'rh' ].Value = readings.Readings[ 'rh' ].Value
                        }
                        if( undefined !== readings.Readings[ 'tF' ] )
                        {
                            device.readings[ 'tF' ] = {}
                            device.readings[ 'tF' ].Value = readings.Readings[ 'tF' ].Value
                        }
                        if( undefined !== readings.Readings[ 'battery_percent' ] )
                        {
                            device.readings[ 'battery_percent' ] = {}
                            device.readings[ 'battery_percent' ].Value = readings.Readings[ 'battery_percent' ].Value
                        }
                        if( undefined !== readings.Readings[ 'measured-temp' ] )
                        {
                            device.readings[ 'measured-temp' ] = {}
                            device.readings[ 'measured-temp' ].Value = readings.Readings[ 'measured-temp' ].Value
                        }
                        if( undefined !== readings.Readings[ 'fillstate' ] )
                        {
                            device.readings[ 'fillstate' ] = {}
                            device.readings[ 'fillstate' ].Value = readings.Readings[ 'fillstate' ].Value
                            device.readings[ 'fillstate' ].Time = readings.Readings[ 'fillstate' ].Time
                        }
                        if( undefined !== readings.Readings[ 'height' ] )
                        {
                            device.readings[ 'height' ] = {}
                            device.readings[ 'height' ].Value = readings.Readings[ 'height' ].Value
                            device.readings[ 'height' ].Time = readings.Readings[ 'height' ].Time
                        }
                        if( undefined !== readings.Readings[ 'motors' ] )
                        {
                            device.readings[ 'motors' ] = {}
                            device.readings[ 'motors' ].Value = readings.Readings[ 'motors' ].Value
                            device.readings[ 'motors' ].Time = readings.Readings[ 'motors' ].Time
                        }
                        if( undefined !== readings.Readings[ 'topEndstop' ] )
                        {
                            device.readings[ 'topEndstop' ] = {}
                            device.readings[ 'topEndstop' ].Value = readings.Readings[ 'topEndstop' ].Value
                            device.readings[ 'topEndstop' ].Time = readings.Readings[ 'topEndstop' ].Time
                        }
                        if( undefined !== readings.Readings[ 'bottomEndstop' ] )
                        {
                            device.readings[ 'bottomEndstop' ] = {}
                            device.readings[ 'bottomEndstop' ].Value = readings.Readings[ 'bottomEndstop' ].Value
                            device.readings[ 'bottomEndstop' ].Time = readings.Readings[ 'bottomEndstop' ].Time
                        }

                        if( undefined !== readings.Readings[ 'desired-temp' ] )
                        {
                            device.readings[ 'desired-temp' ] = {}
                            device.readings[ 'desired-temp' ].Value = readings.Readings[ 'desired-temp' ].Value
                        }
                        if( undefined !== readings.Readings[ 'desiredTemp' ] )
                        {
                            device.readings[ 'desiredTemp' ] = {}
                            device.readings[ 'desiredTemp' ].Value = readings.Readings[ 'desiredTemp' ].Value
                        }
                        if( undefined !== readings.Readings[ 'battery' ] )
                        {
                            device.readings[ 'battery' ] = {}
                            device.readings[ 'battery' ].Value = readings.Readings[ 'battery' ].Value
                            device.readings[ 'battery' ].Time = readings.Readings[ 'battery' ].Time
                        }
                        if( undefined !== readings.Readings[ 'batteryLevel' ] )
                        {
                            device.readings[ 'batteryLevel' ] = {}
                            device.readings[ 'batteryLevel' ].Value = readings.Readings[ 'batteryLevel' ].Value
                            device.readings[ 'batteryLevel' ].Time = readings.Readings[ 'batteryLevel' ].Time
                        }
                        if( undefined !== readings.Readings[ 'BATTERY_STATE' ] )
                        {
                            device.readings[ 'BATTERY_STATE' ] = {}
                            device.readings[ 'BATTERY_STATE' ].Value = readings.Readings[ 'BATTERY_STATE' ].Value
                            device.readings[ 'BATTERY_STATE' ].Time = readings.Readings[ 'BATTERY_STATE' ].Time
                        }

                        if( undefined === device.type && undefined !== device.internals.type )
                        {
                            device.type = device.internals.type
                        }

                        dbList.push( {
                            key : key,
                            item: JSON.parse( JSON.stringify( device ) )
                        } )

                    }

                }

            }

            this.parent.database.writeList( 'devices', dbList )
                .then( () =>
                {
                    return resolve()
                } )

        } )

    }

    getDevicesList()
    {
        return new Promise( resolve =>
        {

            let jsonReqList = []
            for( let d in this.parent.cache.devices.keys )
            {

                let device = this.parent.cache.devices.map.get( this.parent.cache.devices.keys[ d ] ),
                    key    = this.parent.cache.devices.keys[ d ]

                switch( device.internals.type.toLowerCase() )
                {
                    case 'httpmod':
                    case 'cul_hm':
                    case 'hmccuchn':
                    case 'zwave':
                    case 'huedevice':
                    case 'structure':
                    case 'mqtt2_device':
                        jsonReqList.push( key )
                        break
                    case 'dummy':
                        jsonReqList.push( key )
                        /*
                        switch( device.name )
                        {
                            case 'NoWorkTomorrow':
                            case 'Heizung_Tank':
                            case 'Klima_Schlafzimmer':
                            case 'Klima_Arbeitszimmer':
                            case 'Tuere_Huehnerhaus':
                                jsonReqList.push( key )
                                break
                            default:
                                //console.log( 'WSS', this.devices[d], this.devices[d].item.type, this.devices[d].item.name )
                                break
                        }*/
                        break
                    default:
                        break
                }

            }

            if( 0 < jsonReqList.length )
            {
                let action  = 'listbase ' + jsonReqList.join( ',' ),
                    message = {
                        method : 'endpoint.command',
                        command: action
                    }

                this.client.request( message )
                    .then( response =>
                    {

                        let stateListJson = this.crypto.decrypt( ( response ) )
                        if( false !== stateListJson )
                        {
                            let stateList = JSON.parse( stateListJson )
                            this.updateDeviceReadings( stateList.Results )
                                .then( () =>
                                {
                                    return resolve()
                                } )
                        }
                        else
                        {
                            return resolve()
                        }

                    } )
                    .catch( () =>
                    {
                        return resolve()
                    } )
            }
            else
            {
                return resolve()
            }

        } )

    }

    getFullDevicesList()
    {

        return new Promise( resolve =>
        {

            let action  = 'jsonlist',
                message = {
                    method : 'endpoint.command',
                    command: action
                }

            this.client.request( message )
                .then( response =>
                {

                    let rawFullDeviceList = this.crypto.decrypt( response )

                    if( false !== rawFullDeviceList )
                    {
                        return resolve( JSON.parse( rawFullDeviceList ) )
                    }

                    return resolve()

                } )

        } )
    }

    getCameras()
    {

        return new Promise( resolve =>
        {

            let message = {
                method : 'endpoint.command',
                command: 'cameras'
            }

            this.client
                .request( message )
                .then( result =>
                {

                    let decryptedCams = this.crypto.decrypt( result )

                    if( false !== decryptedCams )
                    {

                        let cams   = JSON.parse( decryptedCams ),
                            dbList = []
                        for( let c in cams )
                        {
                            dbList.push( {
                                key : c,
                                item: cams[ c ]
                            } )
                        }

                        this.parent.database.writeList( 'cameras', dbList )
                            .then( () =>
                            {
                                return resolve()
                            } )

                    }
                    else
                    {
                        return resolve()
                    }

                } )
                .catch( () =>
                {
                    return resolve()
                } )

        } )

    }

    mergeDevices( list )
    {

        let changes = false

        for( let rawDeviceType in list )
        {
            switch( rawDeviceType.toLowerCase() )
            {

                case 'httpmod':
                case 'cul_hm':
                case 'hmccuchn':
                case 'zwave':
                case 'huedevice':
                case 'structure':
                case 'mqtt2_device':
                case 'dummy':

                    for( let key in list[ rawDeviceType ] )
                    {

                        let test = this.parent.cache.devices.map.get( key )
                        if( !test )
                        {
                            list[ rawDeviceType ][ key ].displayName = list[ rawDeviceType ][ key ].name
                            list[ rawDeviceType ][ key ].isUnsorted = true
                            this.parent.cache.devices.map.set( key, list[ rawDeviceType ][ key ] )
                            this.parent.cache.unsorted.push( key )
                            changes = true
                        }

                    }
                    break

            }
        }

        if( changes )
        {
            this.parent.cache.unsortedHash = this.f.deviceHash( this.parent.cache.unsorted, true )
        }

    }

    fetchFullDevicesList()
    {
        if( this.ready
            && !this.paused )
        {

            this.logger.log( 'DevicesRefresh::fetchAll', 'fetchAll requesting all states' )
            this.ready = false

            this.getFullDevicesList()
                .then( fullDevicesList =>
                {

                    this.mergeDevices( fullDevicesList )
                    this.ready = true

                } )
        }
    }

    fetchAll()
    {

        console.log( 'DBG > FETCH ALL', this.ready, this.paused )

        if( this.ready
            && !this.paused )
        {

            let start = Date.now()
            this.logger.log( 'DevicesRefresh::fetchAll', 'fetchAll requesting all states' )
            this.ready = false

            this.getDevicesList()
                .then( () =>
                {

                    this.logger.log( 'DevicesRefresh::fetchAll', 'fetchAll finished after', Date.now() - start, 'ms' )
                    this.parent.prepareCache( true )
                    this.ready = true

                } )

        }
    }

    fetchImages()
    {
        if( ( true === this.getState( 'camera-view-active' ) || true === this.firstrun )
            && this.ready
            && !this.paused )
        {

            let start = Date.now()
            this.logger.log( 'DevicesRefresh::fetchImages', 'fetchImages requesting all camera images' )
            this.ready = false

            this.getCameras()
                .then( () =>
                {
                    this.logger.log( 'DevicesRefresh::fetchImages', 'fetchImages finished after', Date.now() - start, 'ms' )
                    this.parent.prepareCache( true )
                    this.ready = true
                    this.firstrun = false

                } )

        }
    }

}